<template>
  <v-container fluid>
    <head-title text="Proyectos" :to="{ name: 'Home' }"></head-title>
    <v-row>
      <v-col cols="12" md="4">
        <project-field v-model="projectId"></project-field>
      </v-col>
      <v-col cols="12" md="1">
        <v-select
          :items="[
            { text: 'Por Fecha', value: 0 },
            { text: 'Por Mes', value: 1 },
            { text: 'Por Período', value: 2 },
          ]"
          label="Mostrar Datos"
          v-model="searchType"
        ></v-select>
      </v-col>
      <v-col cols="12" md="2" v-if="searchType === 0">
        <date-field label="Fecha" v-model="date"></date-field>
      </v-col>
      <v-col cols="12" md="2" v-else-if="searchType === 1">
        <date-field label="Mes" v-model="date" type="month"></date-field>
      </v-col>
      <v-col cols="12" md="2" v-if="searchType === 2">
        <date-field label="De" v-model="date"></date-field>
      </v-col>
      <v-col cols="12" md="2" v-if="searchType === 2">
        <date-field label="Hasta" v-model="until"></date-field>
      </v-col>
      <v-col cols="12" md="1" class="text-left">
        <v-btn class="mx-1" fab dark small color="blue darken-3" @click="search()">
          <v-icon dark>mdi-database-search</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" md="1" class="text-left">
        <v-btn fab dark small color="blue darken-3" @click="newDetail()">
          <v-icon color="white">mdi-plus</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" md="1" class="text-left">
        <v-btn fab dark small color="blue darken-3" @click="exportXlsxReport()">
          <v-icon color="white">mdi-file</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="auto" class="mr-auto" v-if="projectCost.length == 0"></v-col>
      <v-col
        v-else
        v-for="(cost, index) in projectCost"
        cols="auto"
        :key="index"
        class="mr-auto"
      >
        <p class="text-center" v-if="cost.name != 'Total'">
          {{ cost.name }}
          <strong>Q{{ cost.total | currency }}</strong>
        </p>
      </v-col>
      <v-col cols="auto">
        <p class="text-right">
          Total
          <strong>Q{{ totalCost | currency }}</strong>
        </p>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :search="filter"
      hide-default-footer
      disable-pagination
      class="elevation-1"
    >
      <template v-slot:top v-if="items.length > 0">
        <v-text-field
          v-model="filter"
          append-icon="mdi-magnify"
          label="Buscar"
          clearable
          class="mx-4"
        ></v-text-field>
      </template>
      <template v-slot:[`item.date_document`]="{ item }">
        <span>{{ item.date_document | datef }}</span>
      </template>
      <template v-slot:[`item.quantity`]="{ item }">
        <span>{{ item.quantity | currency }}</span>
      </template>
      <template v-slot:[`item.price`]="{ item }">
        <span>{{ item.price | currency }}</span>
      </template>
      <template v-slot:[`item.amount`]="{ item }">
        <span>{{ item.amount | currency }}</span>
      </template>
    </v-data-table>
    <new-detail
      :dialog="detailDialog"
      @close="detailDialog = false"
      @saved="search()"
    ></new-detail>
  </v-container>
</template>
<script>
import requests from "@/services/requests";
import xlsx from "@/services/sheetJs";

export default {
  components: {
    HeadTitle: () => import("@/components/base/HeaderTitle"),
    projectField: () => import("@/components/fields/ProjectField"),
    DateField: () => import("@/components/fields/DateField.vue"),
    newDetail: () => import("@/components/projects/Create.vue"),
  },
  data: () => ({
    showTotal: false,
    totalCost: 0,
    projectCost: [],
    columns: [
      [
        "Fecha",
        "Tipo Documento",
        "No. Documento",
        "Sucursal",
        "Descripción",
        "Tipo Gasto",
        "Unidad",
        "Cantidad",
        "Precio",
        "Subtotal",
      ],
    ],
    date: "",
    until: "",
    detailDialog: false,
    searchType: null,
    items: [],
    loading: false,
    invoiceDialog: false,
    statusId: {},
    projectId: {},
    companyId: {},
    filter: "",
    headers: [
      { text: "Fecha documento", value: "date_document" },
      { text: "Tipo Documento", value: "document" },
      { text: "No. Documento", value: "document_code" },
      { text: "Sucursal", value: "company.second_name" },
      { text: "Descripción", value: "description" },
      { text: "Tipo Gasto", value: "type.type" },
      { text: "Unidad", value: "unit", align: "end" },
      { text: "Cantidad", value: "quantity", align: "end" },
      { text: "Precio", value: "price", align: "end" },
      { text: "Subtotal", value: "amount", align: "end" },
    ],
  }),
  mounted() {
    this.searchType = 0;
  },
  methods: {
    calculateCost(items) {
      this.projectCost = [];
      let initialValue = 0;
      let total = items.reduce(function (previousValue, currentValue) {
        return Number(previousValue) + Number(currentValue.amount);
      }, initialValue);
      this.totalCost = total;
      // sucursales
      let unique = [...new Set(items.map((item) => item.company.second_name))];

      // calcula total por cada sucursal
      for (var a = 0; a < unique.length; a++) {
        var company = unique[a];
        let valores = items
          .filter(({ company }) => company.second_name === unique[a])
          .reduce((sum, val) => Number(sum) + Number(val.amount), 0);

        this.projectCost.push({ name: company, total: valores });
      }
    },
    exportXlsxReport() {
      if (this.items.length > 0) {
        var data = this.items.map((row) => ({
          date: row.date_document,
          document_type: row.document,
          document_code: row.document_code,
          company: row.company.second_name,
          description: row.description,
          type: row.type.type,
          unit: row.unit,
          quantity: row.quantity,
          price: row.price,
          amount: row.amount,
        }));
        xlsx.exportXlsx(
          data,
          this.columns,
          "Consumo",
          this.projectId.label_data + ".xlsx"
        );
      } else {
        this.$toasted.global.info({
          message: "No hay datos",
        });
      }
    },
    getList(uri) {
      this.loading = true;
      requests.get(uri).then((response) => {
        if (response.status == 200) {
          this.items = response.data;
          this.calculateCost(this.items);
          this.showTotal = true;
        }
        this.loading = false;
      });
    },
    search() {
      this.projectCost = [];
      this.totalCost = 0;
      var uri = "api/project/detail-project/";
      if (this.date === null) {
        this.$toasted.global.info({
          message: "Seleccione la fecha",
        });
        return;
      }

      if ((this.projectId.id == undefined) | (this.projectId.id <= 0)) {
        this.$toasted.global.info({
          message: "Seleccione un proyecto",
        });
        return;
      }
      if (this.searchType === 0) {
        uri += "?date=" + this.date;
      } else if (this.searchType === 1) {
        uri +=
          "?year=" + this.date.substring(0, 4) + "&month=" + this.date.substring(5, 7);
      } else if (this.searchType === 2 || this.searchType === 3) {
        if (this.until === null) {
          this.$toasted.global.info({
            message: "Seleccione la fecha",
          });
          return;
        } else {
          uri += "?date=" + this.date + "&until=" + this.until;
          if (this.searchType === 3) {
            uri += "&period=true";
          }
        }
      }
      uri += "&project=" + this.projectId.id;
      if (this.statusId.id != undefined) {
        uri += "&status=" + this.statusId.id;
      }
      this.getList(uri);
    },
    newDetail() {
      this.detailDialog = true;
    },
  },
  watch: {
    searchType(newValue) {
      // fecha
      if (newValue === 0) {
        this.date = new Date().toISOString().substring(0, 10);
      }
      // mes
      if (newValue === 1) {
        this.date = new Date().toISOString().substring(0, 7);
      }
      // periodo
      if (newValue === 2) {
        this.date = new Date().toISOString().substring(0, 10);
        this.until = new Date().toISOString().substring(0, 10);
      }
    },
  },
};
</script>
